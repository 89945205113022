<template>
    <div class="kleine-boxen" :class="{even: children % 2 == 0}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        animated: Boolean,
        switchState: Boolean,
        duration: {
            type: Number,
            default: 1000
        }
    },
    data() {return {
        current: 0,
        children: 0
    }},
    mounted() {
        const children = this.$el.children
        this.children = children.length
        if (this.animated) {
            children[0].classList.add('current')
            setInterval(() => {
                const elements = this.$el.children
                let i = this.current
                if (!this.switchState)
                    elements[i].classList.toggle('current')
                i++
                if (i >= elements.length)
                    i = 0
                elements[i].classList.toggle('current')
                this.$set(this, 'current', i)
            }, this.duration)
        }
    }
}
</script>

<style lang="scss" scoped>
@use '../styles/sizes';
@use '../styles/colors' as *;
@use '../styles/mixins' as *;

.kleine-boxen {
	display: grid;
	position: relative;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1em;
	margin: 1.5em auto;

    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }

	& > * {
		background-color: $blau;
		padding: 1.5em;
		text-align: center;
		color: #fff;
		font-size: 1.3rem;
		transition: background-color 0.25s ease-in-out;
	}

	& > button {
		border: none;
		font-weight: inherit;
	}


	&.tabs {
        @media (max-width: sizes.$ipad) {
            max-width: 400px;
            gap: 4em;
        }

		> * {
			cursor: pointer;
			background-color: #fff;
			color: $blau;
			position: relative;
			z-index: 2;
		}

        > .active::before,
        > :hover::before {
            top: calc(100% + .5em);
        }

		> :hover {
			background-color: #fff !important;
		}

		> ::before {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			border-color: #fff;
			border-top: 1.5em solid #fff;
			border-bottom: 0;
			border-right: 3em solid transparent;
			border-left: 3em solid transparent;
			transition: top 0.25s ease-in-out;
			z-index: -1;
		}
	}

	&.text-box > * {
        font-size: 1.1rem;  
        box-shadow: 0 0 .5em grey;      
        background-color: $weiss-leicht-transparent;
        color: $blau;

        &:hover {
            background-color: $weiss-leicht-transparent !important;
        }
    }

    &.blend-in {
        > * {
			opacity: 0;
			transform: translateY(30%);
			transition: all 0.5s ease-in-out;
            @include gestaffelte-verzoegerung(1, 3);
		}

        &.visible > * {
            opacity: 1;
            transform: translateY(0) !important;
        }
    }
}
</style>